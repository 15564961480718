import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/login/index'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Customers
        {
          name: 'Customers',
          path: '/customers',
          component: () => import('@/views/customers/Customers'),
        },
        {
          name: 'Customer Profile',
          path: '/customer/:id',
          component: () => import('@/views/customers/Profile'),
        },
        {
          name: 'Driver Profile',
          path: '/driver/:id',
          component: () => import('@/views/drivers/profile'),
        },
        {
          name: 'Request Detail',
          path: '/request/:id',
          component: () => import('@/views/requests/Detail'),
        },
        {
          name: 'Dispatchers',
          path: '/dispatchers',
          component: () => import('@/views/dispatchers/dispatchers'),
        },
        {
          path: '/dispatcher-general-details/:id',
          name: 'Dispatcher General Details',
          component: () => import('@/views/dispatchers/general-details'),
        },
        {
          path: '/dispatcher-bookings/:id',
          name: 'Dispatcher Bookings',
          component: () => import('@/views/dispatchers/bookings'),
        },
        {
          path: '/dispatcher-drivers/:id',
          name: 'Dispatcher Drivers',
          component: () => import('@/views/dispatchers/drivers'),
        },
        {
          path: '/dispatcher-vehicles/:id',
          name: 'Dispatcher Vehicles',
          component: () => import('@/views/dispatchers/vehicles'),
        },
        {
          path: '/dispatcher-transactions/:id',
          name: 'Dispatcher Transactions',
          component: () => import('@/views/dispatchers/transactions'),
        },
        {
          name: 'Quote list',
          path: '/quotes/:id',
          component: () => import('@/views/requests/Quotes'),
        },
        {
          name: 'Invited Customer',
          path: '/invitedUserList/:id',
          component: () => import('@/views/inviteCodes/invitedUsersList'),
        },
        {
          name: 'User List with Vip Code',
          path: '/userlistwithvipcode/:code',
          component: () => import('@/views/vipCodes/userlistwithvipcode'),
        },
        // Add Customers
        // {
        //   name: 'Add Customer',
        //   path: '/customers/addNew',
        //   component: () => import('@/views/customers/addNew'),
        // },
        // Professionals
        {
          name: 'Drivers',
          path: '/drivers',
          component: () => import('@/views/drivers/drivers'),
        },
        // Add Professional
        // {
        //   name: 'Add Professionals',
        //   path: '/professionals/addNew',
        //   component: () => import('@/views/professionals/addNew'),
        // },
        // Call Requests
        {
          name: 'Bookings',
          path: '/callRequests',
          component: () => import('@/views/requests/CallRequests'),
        },
        // Industries
        {
          name: 'Industries',
          path: '/industries',
          component: () => import('@/views/industries/Industries'),
        },
        // Companies
        {
          name: 'Companies',
          path: '/companies',
          component: () => import('@/views/companies/Companies'),
        },
        {
          name: 'Schools',
          path: '/schools',
          component: () => import('@/views/schools/Schools'),
        },
        // PayoutReports
        {
          name: 'Payouts',
          path: '/payouts',
          component: () => import('@/views/payoutReports'),
        },
        // VIPCodes
        {
          name: 'VIP Codes',
          path: '/vipCodes',
          component: () => import('@/views/vipCodes'),
        },
        // inviteCodes
        {
          name: 'Invite Codes',
          path: '/inviteCodes',
          component: () => import('@/views/inviteCodes'),
        },
        {
          name: 'Review',
          path: '/reviews',
          component: () => import('@/views/reviews'),
        },
        // Discount
        {
          name: 'Discount',
          path: '/discount',
          component: () => import('@/views/discount/DiscountCodes'),
        },
        // Gift Cards
        {
          name: 'Gift Card',
          path: '/giftcard',
          component: () => import('@/views/giftCard/giftcard'),
        },
        // Transactions
        {
          name: 'Transactions',
          path: '/transactions',
          component: () => import('@/views/transactions/Transactions'),
        },
        {
          name: 'Web Pages',
          path: '/web-pages',
          component: () => import('@/views/staticPages/Pages'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },

        {
          name: 'Notification',
          path: 'notifications',
          component: () => import('@/views/notifications/Notifications'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
